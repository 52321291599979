import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { EventsContainer, EventTile } from './styled.components';
import {
  FlexColumnContainer,
  FlexGridCenteredContainer,
  H2Style,
  ParagraphLargeStyle
} from '../../../styled.components';
import { StaticImage } from 'gatsby-plugin-image';

const EventsSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 2,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/2.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 3,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/3.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 4,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/4.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 5,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/5.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 6,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/6.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 7,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/7.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 8,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/8.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 9,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/9.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 10,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/10.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 11,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/11.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 12,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/12.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 13,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/13.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 14,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/14.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 15,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/15.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 16,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/events/16.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  const renderItems = items?.map((item) => {
    return <EventTile key={item.id}>{item.img}</EventTile>;
  });

  return (
    <>
      <EventsContainer>
        <div className="container">
          <FlexColumnContainer gap="8px">
            <ParagraphLargeStyle color="#5FA5C5">{t`partners.events-sub-title`}</ParagraphLargeStyle>
            <H2Style color="#0F5575">{t`partners.events-title`}</H2Style>
          </FlexColumnContainer>
          <FlexGridCenteredContainer>{renderItems}</FlexGridCenteredContainer>
        </div>
      </EventsContainer>
    </>
  );
};

export default EventsSection;
