import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ProjectsContainer, ProjectTile } from './styled.components';
import {
  FlexColumnContainer,
  FlexGridCenteredContainer,
  H2Style,
  ParagraphLargeStyle
} from '../../../styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
const ProjectsSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      url: 'https://www.innovationshub.pl/program-inkubacyjny',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/incubation_program.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 2,
      url: 'https://www.innovationshub.pl/program-inkubacyjny/#final-gala2',
      isAnchor: true,
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/summit.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 3,
      url: 'https://www.innovationshub.pl/future-academy',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/future_academy.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 4,
      url: 'https://www.innovationshub.pl/incubator',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/incubator.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 5,
      url: 'https://www.innovationshub.pl/mpk',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/innovations_academy_1.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 6,
      url: 'https://www.innovationshub.pl/fsts',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/innovations_academy_2.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 7,
      url: 'https://www.innovationshub.pl/biz-on',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/bizon.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 8,
      url: 'https://www.innovationshub.pl/documents',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/raport.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 9,
      url: 'https://www.youtube.com/watch?v=gSLUTJtAiyk',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/new_generation.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 10,
      url: 'https://www.innovationshub.pl/ens/',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/north_star.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 11,
      url: 'https://www.prezydent.pl/aktualnosci/wydarzenia/startupy-w-palacu-po-raz-siodmy,85511',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/palace.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      id: 12,
      url: 'https://www.innovationshub.pl/akademia-mlodego-innowatora',
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/projects/young_academy.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  const renderItems = items?.map((item) => {
    return (
      <>
        {item.isAnchor !== true && (
          <ProjectTile
            as="a"
            key={item.id}
            href={item.url}
            target="_blank"
            rel="noreferrer nofollow">
            {item.img}
          </ProjectTile>
        )}
        {item.isAnchor && (
          <ProjectTile>
            <AnchorLink
              stripHash={true}
              to="/program-inkubacyjny/#final-gala2"
              title="Final Gala Section">
              {item.img}
            </AnchorLink>
          </ProjectTile>
        )}
      </>
    );
  });

  return (
    <>
      <ProjectsContainer>
        <div className="container">
          <FlexColumnContainer gap="8px">
            <ParagraphLargeStyle color="#5FA5C5">{t`partners.projects-sub-title`}</ParagraphLargeStyle>
            <H2Style color="#0F5575">{t`partners.projects-title`}</H2Style>
          </FlexColumnContainer>
          <FlexGridCenteredContainer>{renderItems}</FlexGridCenteredContainer>
        </div>
      </ProjectsContainer>
    </>
  );
};

export default ProjectsSection;
