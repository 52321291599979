import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  FlexColumnContainer,
  FlexRowContainer,
  H2Style,
  H4Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle
} from '../../../styled.components';
import { BecomePartnerContainer, CarouselContainer } from './styled.components';
import { Trans } from 'react-i18next';
import Slider from 'react-slick';
import { StaticImage } from 'gatsby-plugin-image';
import { SampleNextArrow, SamplePrevArrow } from '../../../sliderArrows';

const BecomePartnerSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/1.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/2.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/3.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/4.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/5.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/6.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/7.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/8.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/9.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/10.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/11.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/12.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/13.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/14.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/15.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/16.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/17.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/18.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/19.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/20.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/bp/21.jpg"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  const totalSlides = items.length;
  const slidesToShow = 1;
  const totalPages = Math.ceil(totalSlides / slidesToShow);
  const [currentPage, setCurrentPage] = React.useState(1);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentPage(Math.ceil(nextSlide / slidesToShow) + 1);
    }
    // responsive: [
    //   {
    //     breakpoint: 1330,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   },
    //   {
    //     breakpoint: 950,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    // ]
  };

  return (
    <>
      <div className="container">
        <BecomePartnerContainer>
          <FlexRowContainer gap="100px">
            <FlexColumnContainer gap="24px" alignItems="flex-start">
              <FlexColumnContainer alignItems="flex-start">
                <FlexColumnContainer gap="8px" alignItems="flex-start">
                  <ParagraphLargeStyle color="#5FA5C5">{t`partners.bp-sub-title`}</ParagraphLargeStyle>
                  <H2Style color="#0F5575">{t`partners.bp-title`}</H2Style>
                </FlexColumnContainer>
                <H4Style color="#5FA5C5">{t`partners.bp-description`}</H4Style>
              </FlexColumnContainer>
              <ParagraphMediumStyle>
                <Trans
                  i18nKey="partners.bp-description-2"
                  components={{
                    b: <b></b>
                  }}
                />
              </ParagraphMediumStyle>
            </FlexColumnContainer>
            <CarouselContainer>
              <Slider {...settings} className="bp-slider">
                {items.map((item, index) => (
                  <div key={index}>
                    <div className="carousel-item">{item.img}</div>
                  </div>
                ))}
              </Slider>
              <div className="slider-pagination">
                {currentPage}/{totalPages}
              </div>
            </CarouselContainer>
          </FlexRowContainer>
        </BecomePartnerContainer>
      </div>
    </>
  );
};

export default BecomePartnerSection;
