import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  EpTextContainer,
  EventPartnerContainer,
  HeroContainer,
  ProvideContainer,
  ReciveContainer,
  TextPRContainer
} from './styled.components';
import {
  FlexColumnContainer,
  FlexRowContainer,
  H1Style,
  H2Style,
  H2SubStyle,
  H4Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle,
  WhiteButton
} from '../../../styled.components';
import { Trans } from 'react-i18next';
import {
  SwitchButton,
  SwitchingButtonsContainer
} from '../../../../documents-page/components/puzzle-canvas-section/styled.components';
import { StaticImage } from 'gatsby-plugin-image';

const EventPartnerSection = () => {
  const { t } = useTranslation();

  const [currentView, setCurrentView] = useState('Ecosystem');
  const [currentData, setCurrentData] = useState();

  const views = [
    {
      id: 'Ecosystem',
      provide: [
        t`partners.ep-ecosystem-provide-1`,
        <Trans
          i18nKey="partners.ep-ecosystem-provide-2"
          components={{
            a: (
              <a
                href="https://bic.uj.edu.pl/aktualnosc/260-jednorozec-tylko-30-razy-bardziej-prawdopodobny-niz-atak-rekina"
                target="_blank"
                rel="noreferrer"></a>
            ),
            b: <b></b>
          }}
        />,
        <Trans
          i18nKey="partners.ep-ecosystem-provide-3"
          components={{
            a: <a href="#guidelines-publication"></a>
          }}
        />,
        <Trans
          i18nKey="partners.ep-ecosystem-provide-4"
          components={{
            a: <a href="#guidelines-graphics"></a>
          }}
        />,
        t`partners.ep-ecosystem-provide-5`
      ],
      recive: [
        t`partners.ep-ecosystem-recive-1`,
        t`partners.ep-ecosystem-recive-2`,
        t`partners.ep-ecosystem-recive-3`,
        t`partners.ep-ecosystem-recive-4`,
        t`partners.ep-ecosystem-recive-5`
      ]
    },
    {
      id: 'Media',
      provide: [
        t`partners.ep-media-provide-1`,
        <Trans
          i18nKey="partners.ep-media-provide-2"
          components={{
            a: <a href="#guidelines-publication"></a>
          }}
        />,
        <Trans
          i18nKey="partners.ep-media-provide-3"
          components={{
            a: <a href="#guidelines-graphics"></a>
          }}
        />,
        t`partners.ep-media-provide-4`
      ],
      recive: [
        t`partners.ep-media-recive-1`,
        t`partners.ep-media-recive-2`,
        t`partners.ep-media-recive-3`,
        t`partners.ep-media-recive-4`,
        t`partners.ep-media-recive-5`,
        t`partners.ep-media-recive-6`
      ]
    },
    {
      id: 'Content',
      provide: [
        t`partners.ep-content-provide-1`,
        t`partners.ep-content-provide-2`,
        <Trans
          i18nKey="partners.ep-content-provide-3"
          components={{
            a: <a href="#guidelines-publication"></a>
          }}
        />,
        <Trans
          i18nKey="partners.ep-content-provide-4"
          components={{
            a: <a href="#guidelines-graphics"></a>
          }}
        />,
        t`partners.ep-content-provide-5`
      ],
      recive: [t`partners.ep-content-recive-1`, t`partners.ep-content-recive-2`]
    },
    {
      id: 'Service',
      provide: [
        t`partners.ep-service-provide-1`,
        t`partners.ep-service-provide-2`
      ],
      recive: [t`partners.ep-service-recive-1`, t`partners.ep-service-recive-2`]
    },
    {
      id: 'Honor',
      provide: [
        <Trans
          i18nKey="partners.ep-honor-provide-1"
          components={{
            li: <li></li>,
            ul: <ul></ul>
          }}
        />,
        <Trans
          i18nKey="partners.ep-honor-provide-2"
          components={{
            a: <a href="#guidelines-publication"></a>
          }}
        />,
        <Trans
          i18nKey="partners.ep-honor-provide-3"
          components={{
            a: <a href="#guidelines-graphics"></a>
          }}
        />,
        t`partners.ep-honor-provide-4`
      ],
      recive: [t`partners.ep-honor-recive-1`]
    }
  ];

  useEffect(() => {
    const filterCurrentView = views.find((view) => {
      return view.id === currentView;
    });
    setCurrentData(filterCurrentView);
  }, [currentView]);

  return (
    <>
      <div className="container">
        <EventPartnerContainer>
          <StaticImage
            class="circle-right"
            src="../../../../../images/partnerzy/ep/ep-circle.webp"
            alt="circle"
            loading="lazy"
          />
          <FlexColumnContainer gap="36px" alignItems="flex-start">
            <EpTextContainer>
              <FlexColumnContainer gap="8px" alignItems="flex-start">
                <ParagraphLargeStyle color="#5FA5C5">{t`partners.ep-sub-title`}</ParagraphLargeStyle>
                <H2Style color="#0F5575">{t`partners.ep-title`}</H2Style>
              </FlexColumnContainer>
              <ParagraphMediumStyle>
                <Trans
                  i18nKey="partners.ep-description"
                  components={{
                    a: <a href="#contact"></a>
                  }}
                />
              </ParagraphMediumStyle>
            </EpTextContainer>
            <FlexColumnContainer gap="24px">
              <FlexColumnContainer gap="4px" alignItems="flex-start">
                <ParagraphMediumStyle color="#0F5575">{t`partners.ep-type`}</ParagraphMediumStyle>
                <SwitchingButtonsContainer>
                  <SwitchButton
                    onClick={() => setCurrentView('Ecosystem')}
                    className={
                      currentView === 'Ecosystem' ? 'checked' : ''
                    }>{t`partners.ep-ecosystem`}</SwitchButton>
                  <SwitchButton
                    onClick={() => setCurrentView('Media')}
                    className={
                      currentView === 'Media' ? 'checked' : ''
                    }>{t`partners.ep-media`}</SwitchButton>
                  <SwitchButton
                    onClick={() => setCurrentView('Content')}
                    className={
                      currentView === 'Content' ? 'checked' : ''
                    }>{t`partners.ep-content`}</SwitchButton>
                  <SwitchButton
                    onClick={() => setCurrentView('Service')}
                    className={
                      currentView === 'Service' ? 'checked' : ''
                    }>{t`partners.ep-service`}</SwitchButton>
                  <SwitchButton
                    onClick={() => setCurrentView('Honor')}
                    className={
                      currentView === 'Honor' ? 'checked' : ''
                    }>{t`partners.ep-honor`}</SwitchButton>
                </SwitchingButtonsContainer>
              </FlexColumnContainer>
              <div id="event-partner"></div>
              {currentData && (
                <FlexRowContainer gap="40px">
                  <ProvideContainer>
                    <H4Style color="#080E14">{t`partners.ep-provide`}</H4Style>
                    {currentData.provide.map((item, index) => (
                      <TextPRContainer key={index}>
                        <ParagraphMediumStyle>{item}</ParagraphMediumStyle>
                      </TextPRContainer>
                    ))}
                  </ProvideContainer>
                  <ReciveContainer>
                    <H4Style color="#080E14">{t`partners.ep-recive`}</H4Style>
                    {currentData.recive.map((item, index) => (
                      <TextPRContainer key={index}>
                        <ParagraphMediumStyle>{item}</ParagraphMediumStyle>
                      </TextPRContainer>
                    ))}
                  </ReciveContainer>
                </FlexRowContainer>
              )}
            </FlexColumnContainer>
          </FlexColumnContainer>
        </EventPartnerContainer>
      </div>
    </>
  );
};

export default EventPartnerSection;
