import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  FlexColumnContainer,
  H2Style,
  H3Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle
} from '../../../styled.components';
import {
  CooperationBottomContainer,
  CooperationContainer,
  CooperationsContainer,
  CooperationTopContainer,
  CooperationTopImagesContainer,
  CooperationTopTitleContainer,
  CooperationTypeContainer
} from './styled.components';
import { StaticImage } from 'gatsby-plugin-image';
const CooperationTypeSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      title: t`partners.ct-strategy-title`,
      description: t`partners.ct-strategy-description`,
      images: [
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/innovation_campus.webp"
          alt="partner"
          loading="lazy"
        />,
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/astra_zeneca.webp"
          alt="partner"
          loading="lazy"
        />,
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/in_post.webp"
          alt="partner"
          loading="lazy"
        />
      ]
    },
    {
      id: 2,
      title: t`partners.ct-content-title`,
      description: t`partners.ct-content-description`,
      images: [
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/ideas.webp"
          alt="partner"
          loading="lazy"
        />,
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/fnp.webp"
          alt="partner"
          loading="lazy"
        />,
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/it.webp"
          alt="partner"
          loading="lazy"
        />
      ]
    },
    {
      id: 3,
      title: t`partners.ct-technical-title`,
      description: t`partners.ct-technical-description`,
      images: [
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/canva.webp"
          alt="partner"
          loading="lazy"
        />,
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/google.webp"
          alt="partner"
          loading="lazy"
        />,
        <StaticImage
          class="partner-image"
          src="../../../../../images/partnerzy/ct/click.webp"
          alt="partner"
          loading="lazy"
        />
      ]
    }
  ];

  const [itemStates, setItemStates] = useState(() =>
    items.map((_, index) => index === 0)
  );

  const renderPartners = items?.map((item, index) => {
    const isExpand = itemStates[index];

    const changeView = () => {
      setItemStates((prevStates) => {
        // If the clicked item is already expanded, collapse all.
        if (prevStates[index]) {
          return prevStates.map(() => false);
        }
        // Otherwise, expand only the clicked item.
        return prevStates.map((_, i) => i === index);
      });
    };

    return (
      <CooperationContainer key={item.id} onClick={changeView}>
        <CooperationTopContainer>
          <CooperationTopTitleContainer>
            <H3Style color="#0F5575">{item.title}</H3Style>
            {isExpand ? (
              <div>
                <svg
                  className="minus-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_119_3546)">
                    <path
                      d="M5 12H19"
                      stroke="#F5FAFD"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_119_3546">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  className="plus-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_119_3546)">
                    <path
                      d="M12 5V19"
                      stroke="#0F5575"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 12H19"
                      stroke="#0F5575"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_119_3546">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </CooperationTopTitleContainer>
          <CooperationTopImagesContainer>
            {item.images.map((image, index) => (
              <div className="partner-div" key={index}>
                {image}
              </div>
            ))}
          </CooperationTopImagesContainer>
        </CooperationTopContainer>
        <CooperationBottomContainer
          style={{
            maxHeight: isExpand ? '500px' : '0',
            padding: isExpand ? '16px' : '0 16px'
          }}>
          <ParagraphMediumStyle>{item.description}</ParagraphMediumStyle>
        </CooperationBottomContainer>
      </CooperationContainer>
    );
  });

  return (
    <>
      <CooperationTypeContainer>
        <div className="container">
          <FlexColumnContainer gap="24px">
            <FlexColumnContainer gap="8px">
              <ParagraphLargeStyle color="#5FA5C5">{t`partners.ct-sub-title`}</ParagraphLargeStyle>
              <H2Style color="#0F5575">{t`partners.ct-title`}</H2Style>
            </FlexColumnContainer>
            <CooperationsContainer>{renderPartners}</CooperationsContainer>
          </FlexColumnContainer>
        </div>
      </CooperationTypeContainer>
    </>
  );
};

export default CooperationTypeSection;
