import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PartnersContainer, PartnerTile } from './styled.components';
import {
  FlexColumnContainer,
  FlexGridCenteredContainer,
  H2Style,
  ParagraphLargeStyle
} from '../../../styled.components';
import { StaticImage } from 'gatsby-plugin-image';

const PartnersSection = () => {
  const { t } = useTranslation();

  const items = [
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct/astra_zeneca.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/OVHCloud.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct/in_post.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/Venture_Cafe_Warsaw_Logo_Horiz.png"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct/innovation_campus.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/ergo_hestia.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/alab.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/pepsico.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/bask.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/uprp.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/gpw.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct/click.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/intros.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    // {
    //   img: (
    //     <StaticImage
    //       className="image"
    //       src="../../../../../images/partnerzy/ct-more/smok.webp"
    //       alt="image"
    //       placeholder="none"
    //       loading="lazy"
    //     />
    //   )
    // },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/gitex_e.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/gitex_a.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/gitex_g.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/north_star.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      img: (
        <StaticImage
          className="image"
          src="../../../../../images/partnerzy/ct-more/mk.webp"
          alt="image"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ];

  const renderItems = items?.map((item, index) => {
    return <PartnerTile key={index}>{item.img}</PartnerTile>;
  });

  return (
    <>
      <PartnersContainer>
        <svg
          className="circle-left"
          xmlns="http://www.w3.org/2000/svg"
          width="198"
          height="154"
          viewBox="0 0 198 154"
          fill="none">
          <path
            d="M77.1092 152.5C66.6466 152.5 58.152 143.974 58.152 133.44C58.152 122.906 66.6466 114.38 77.1092 114.38C87.5719 114.38 96.0664 122.906 96.0664 133.44C96.0664 143.974 87.5719 152.5 77.1092 152.5Z"
            fill="#0F5575"
            stroke="#0F5575"
            strokeWidth="3"
          />
          <path
            d="M154.222 130.343C131.989 130.343 113.943 112.248 113.943 89.9004C113.943 67.5523 131.989 49.458 154.222 49.458C176.454 49.458 194.5 67.5523 194.5 89.9004C194.5 112.248 176.454 130.343 154.222 130.343Z"
            stroke="#0F5575"
            strokeWidth="7"
          />
          <ellipse
            cx="4.27849"
            cy="56.4398"
            rx="56.4398"
            ry="57.2801"
            transform="rotate(90 4.27849 56.4398)"
            fill="#0F5575"
          />
        </svg>
        <svg
          className="circle-right"
          xmlns="http://www.w3.org/2000/svg"
          width="82"
          height="173"
          viewBox="0 0 82 173"
          fill="none">
          <circle cx="17.5" cy="17.5" r="15" stroke="#5FA5C5" strokeWidth="5" />
          <circle cx="70" cy="119" r="54" fill="#5FA5C5" />
        </svg>
        <div className="container">
          <FlexColumnContainer gap="8px">
            <ParagraphLargeStyle color="#5FA5C5">{t`partners.partners-sub-title`}</ParagraphLargeStyle>
            <H2Style color="#0F5575">{t`partners.partners-title`}</H2Style>
          </FlexColumnContainer>
          <FlexGridCenteredContainer gap="8px">
            {renderItems}
          </FlexGridCenteredContainer>
        </div>
      </PartnersContainer>
    </>
  );
};

export default PartnersSection;
