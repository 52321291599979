import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  AdditionallyContainer,
  AdditionallyImage,
  AdditionallyTile
} from '../additionally/styled.components';
import {
  FlexGridLeftContainer,
  H4Style,
  ParagraphSmallStyle
} from '../../../styled.components';
const AdditionallySection = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      icon: '🚀',
      title: t`partners.additionally-tile1-title`,
      description: t`partners.additionally-tile1-description`
    },
    {
      id: 2,
      icon: '📊',
      title: t`partners.additionally-tile2-title`,
      description: t`partners.additionally-tile2-description`
    },
    {
      id: 3,
      icon: '🧑‍🔬',
      title: t`partners.additionally-tile3-title`,
      description: t`partners.additionally-tile3-description`
    },
    {
      id: 4,
      icon: '🗓️',
      title: t`partners.additionally-tile4-title`,
      description: t`partners.additionally-tile4-description`
    },
    {
      id: 5,
      icon: '🪴',
      title: t`partners.additionally-tile5-title`,
      description: t`partners.additionally-tile5-description`
    },
    {
      id: 6,
      icon: '🤝',
      title: t`partners.additionally-tile6-title`,
      description: t`partners.additionally-tile6-description`
    }
  ];

  const renderItems = items?.map((item) => {
    return (
      <AdditionallyTile key={item.id}>
        <AdditionallyImage>{item.icon}</AdditionallyImage>
        <H4Style color="#0F5575">{item.title}</H4Style>
        <ParagraphSmallStyle>{item.description}</ParagraphSmallStyle>
      </AdditionallyTile>
    );
  });

  return (
    <>
      <AdditionallyContainer>
        <div className="container">
          <FlexGridLeftContainer gap="16px 20px">
            {renderItems}
          </FlexGridLeftContainer>
        </div>
      </AdditionallyContainer>
    </>
  );
};

export default AdditionallySection;
