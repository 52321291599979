import styled from 'styled-components';

export const CaseStudiesContainer = styled.div`
  background: var(
    --Linear-Blues,
    linear-gradient(263deg, #6ebde9 0%, #0f5575 100%)
  );
  padding: 60px 0;
`;

export const CsTile = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 16px;
  overflow: hidden; /* Ensures children don't exceed border-radius */
  background: #fff;

  box-shadow: 0px 0px 20px 0px rgba(15, 83, 115, 0.2);
`;

export const CsTileUp = styled.div`
  display: flex;
  padding: 24px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  .image {
    img {
      width: 170px !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .image {
      img {
        width: 120px !important;
      }
    }
  }
`;

export const CsTileBottom = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  background: var(--UI-Blue-Tint, #f5fafd);
  min-height: 410px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const CsTileBottomLeft = styled.div`
  display: flex;
  padding: 24px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const CsQuote = styled.p`
  color: var(--UI-Black, #080e14);
  font-family: Raleway;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.32px;
`;

export const CsPersonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  .image {
    flex-shrink: 0;
    img {
      width: 52px;
      height: 52px;
      border-radius: 52px;
    }
  }
`;

export const CsTileBottomRight = styled.div`
  display: flex;
  padding: 24px !important;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 550px;
  box-sizing: border-box;
  align-self: stretch;

  .video-container {
    border-radius: 8px;
    overflow: hidden;
    height: 300px;
  }

  .tile-image {
    border-radius: 8px;
  }

  @media only screen and (max-width: 1200px) {
    margin: auto;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    .video-container {
      height: 250px;
    }
  }
`;

export const TwoImagesContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  .tile2-image {
    width: 323px;
    border-radius: 8px;
  }
  .image1 {
    margin-left: auto;
  }
`;
