import styled from 'styled-components';
import heroImage from '../../../../../images/partnerzy/hero/hero_partnerzy.webp';

export const HeroContainer = styled.div`
  margin-top: var(--menu-height);
  overflow: hidden;
  background-size: cover !important;
  background-position: center;
  width: 100%;
  background-image: url(${heroImage});
  display: block;
  background-attachment: scroll;

  .container {
    display: flex;
    box-sizing: border-box;
    padding: 60px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    h1,
    h2 {
      text-align: center;
    }

    h2 {
      margin-bottom: 120px;
    }
  }
`;
