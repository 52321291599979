import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { HeroContainer } from './styled.components';
import {
  FlexColumnContainer,
  H1Style,
  H2SubStyle,
  WhiteButton
} from '../../../styled.components';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroContainer>
        <div className="container">
          <FlexColumnContainer>
            <H1Style>{t`partners.hero-title`}</H1Style>
            <H2SubStyle>{t`partners.hero-sub-title`}</H2SubStyle>
          </FlexColumnContainer>
          <WhiteButton
            as="a"
            href="#case-study">{t`partners.hero-button`}</WhiteButton>
        </div>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
