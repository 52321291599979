import styled from 'styled-components';
import { DownloadTile } from '../../../../documents-page/components/puzzle-canvas-section/styled.components';
import { FlexRowContainer } from '../../../styled.components';
export const AdditionalMaterialsContainer = styled.div`
  background: var(--UI-Blue-Tint, #f5fafd);

  @media only screen and (max-width: 1200px) {
    ${FlexRowContainer} {
      gap: 0;
    }
  }
`;

export const ADLeftContainer = styled.div`
  display: flex;
  margin: auto 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  ${DownloadTile} {
    width: 460px;
    height: 70px;
  }

  @media only screen and (max-width: 1200px) {
    padding-top: 60px;
  }

  @media only screen and (max-width: 700px) {
    ${DownloadTile} {
      width: 100%;
      height: auto;
    }
  }
`;
