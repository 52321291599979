import React, { useContext, useEffect, useState } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  CaseStudiesContainer,
  CsPersonContainer,
  CsQuote,
  CsTile,
  CsTileBottom,
  CsTileBottomLeft,
  CsTileBottomRight,
  CsTileUp,
  TwoImagesContainer
} from '../case-studies/styled.components';
import {
  CarouselContainer,
  FlexColumnContainer,
  H2Style,
  H4Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle
} from '../../../styled.components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import Video from '../../../../../components/video';
import Slider from 'react-slick';
import { SampleNextArrow, SamplePrevArrow } from '../../../sliderArrows';
import { graphql, useStaticQuery } from 'gatsby';
import getCurrentTranslations from '../../../../../components/contentful-translator';

const CaseStudiesSection = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);
  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudies(filter: { page: { in: ["Dla partnerów"] } }) {
        edges {
          node {
            createdAt
            node_locale
            title
            page
            headerImage {
              gatsbyImageData
            }
            description {
              description
            }
            wideoLink
            tileImage {
              gatsbyImageData
            }
            personName
            personPosition
            personImage {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const [caseStudies, setCaseStudies] = useState();
  const [totalSlides, setTotalSlides] = useState();
  const slidesToShow = 1;
  const totalPages = Math.ceil(totalSlides / slidesToShow);
  // const marginRight =
  //   totalPages === 2 ? -totalPages * 45 : -totalPages * 45 + totalPages * 2;

  useEffect(() => {
    const getCaseStudies = () => {
      let getCaseStudies = getCurrentTranslations(
        data.allContentfulCaseStudies.edges,
        language
      );
      console.log(getCaseStudies);
      getCaseStudies = getCaseStudies.filter(
        (item) => item.node.title !== 'Placeholder (nie usuwać)'
      );
      setCaseStudies(getCaseStudies);
      setTotalSlides(getCaseStudies.length);
    };
    getCaseStudies();
  }, [data.allContentfulCaseStudies, language]);

  const [currentPage, setCurrentPage] = React.useState(1);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoplaySpeed: 2000,
    // autoplay: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentPage(Math.ceil(nextSlide / slidesToShow) + 1);
    }
  };

  const renderItems = caseStudies?.map((item, index) => {
    return (
      <CsTile key={index}>
        <CsTileUp>
          <GatsbyImage
            alt={item.node.title}
            placeholder={item.node.title}
            className="image"
            image={getImage(item.node.headerImage.gatsbyImageData)}
          />
        </CsTileUp>
        <CsTileBottom>
          <CsTileBottomLeft>
            <CsQuote>{item.node.description.description}</CsQuote>
            <CsPersonContainer>
              <GatsbyImage
                alt={item.node.personName}
                placeholder={item.node.personName}
                className="image"
                image={getImage(item.node.personImage.gatsbyImageData)}
              />
              <FlexColumnContainer gap="0" alignItems="flex-start">
                <H4Style color="#0F5575">{item.node.personName}</H4Style>
                <ParagraphMediumStyle color="#5FA5C5">
                  {item.node.personPosition}
                </ParagraphMediumStyle>
              </FlexColumnContainer>
            </CsPersonContainer>
          </CsTileBottomLeft>
          <CsTileBottomRight>
            {item.node.wideoLink !== null && item.node.tileImage === null && (
              <Video src={item.node.wideoLink} />
            )}
            {item.node.tileImage !== null &&
              item.node.tileImage.length === 2 && (
                <TwoImagesContainer>
                  {item.node.tileImage.map((image, index) => (
                    <GatsbyImage
                      key={index}
                      alt="Tile image"
                      placeholder="Tile image"
                      className={`tile2-image image${index}`}
                      image={getImage(image.gatsbyImageData)}
                    />
                  ))}
                </TwoImagesContainer>
              )}
            {item.node.tileImage !== null &&
              item.node.tileImage.length === 1 && (
                <GatsbyImage
                  alt="Tile image"
                  placeholder="Tile image"
                  className="tile-image"
                  image={getImage(item.node.tileImage[0].gatsbyImageData)}
                />
              )}
          </CsTileBottomRight>
        </CsTileBottom>
      </CsTile>
    );
  });

  return (
    <>
      <CaseStudiesContainer>
        <div className="container">
          <FlexColumnContainer gap="80px" alignItems="flex-start">
            <FlexColumnContainer gap="24px" alignItems="flex-start">
              <FlexColumnContainer gap="8px" alignItems="flex-start">
                <ParagraphLargeStyle color="#F5FAFD">{t`partners.cs-sub-title`}</ParagraphLargeStyle>
                <H2Style>{t`partners.cs-title`}</H2Style>
              </FlexColumnContainer>
              <ParagraphMediumStyle
                style={{ maxWidth: '580px' }}
                color="#F5FAFD">{t`partners.cs-description`}</ParagraphMediumStyle>
            </FlexColumnContainer>
            <CarouselContainer>
              {caseStudies && caseStudies.length > 1 && (
                <div
                  // style={{ marginRight: `${marginRight}px` }}
                  className="slider-pagination">
                  {currentPage}/{totalPages}
                </div>
              )}
              <Slider {...settings}>{caseStudies && renderItems}</Slider>
            </CarouselContainer>
          </FlexColumnContainer>
        </div>
      </CaseStudiesContainer>
    </>
  );
};

export default CaseStudiesSection;
