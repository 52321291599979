import styled from 'styled-components';

export const BecomePartnerContainer = styled.div`
  padding: 60px 0px;

  p {
    text-align: left;
    justify-content: left;
  }
`;

export const CarouselContainer = styled.div`
  /* width: 46%; */
  width: 545px;
  box-sizing: border-box;
  position: relative;
  margin: auto;

  .carousel-item {
    margin: auto;
    vertical-align: middle;
    text-align: center;
  }
  .image {
    box-sizing: border-box;
    width: 479px !important;
    img {
      box-sizing: border-box;
      height: 382px !important;
      width: 479px !important;
      border-radius: 16px;
    }
  }

  .bp-slider .carousel-item {
    padding: 0;
  }

  .bp-slider .slick-arrow {
    display: flex;
    width: 24.8px;
    height: 24.8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 2px solid var(--UI-Dark-Blue, #0f5575);
    top: 83.5%;
    transform: none;
    z-index: 2;
    transition: all 0.2s ease-out;
  }

  .bp-slider .slick-prev {
    left: 30px;
  }

  .bp-slider .slick-next {
    right: 30px;
  }

  .bp-slider .slick-arrow:hover {
    background: var(--UI-Light-Blue, #4ebfee);
    border: 2px solid var(--UI-Dark-Blue, #4ebfee);
  }

  .bp-slider .slick-arrow:hover path {
    stroke: white !important;
  }

  .bp-slider .slick-dots {
    bottom: 45px;
    margin-left: -23px !important;
  }

  .bp-slider .slick-dots li {
    margin: 0 4px;
  }

  .bp-slider .slick-dots li button:before {
    display: none;
  }

  .bp-slider .slick-dots li button {
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background: var(--UI-Grey-blue, #c4dfec);
  }

  .bp-slider .slick-dots .slick-active {
    width: 32px;
  }

  .bp-slider .slick-dots .slick-active button {
    width: 32px;
    background: var(--UI-Light-Blue, #4ebfee);
  }

  .slider-pagination {
    color: var(--Mid, #0f5575);
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -65px;
    text-align: center;
  }

  // dots are to wide with 21 photos
  .bp-slider .slick-dots {
    display: none !important;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
    .image {
      width: 90% !important;
      img {
        margin: 0 auto;
        width: 90% !important;
      }
    }
    .bp-slider .slick-dots {
      display: none !important;
    }
  }
  @media only screen and (max-width: 700px) {
    .image {
      img {
        width: 100% !important;
        height: 300px !important;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .image {
      img {
        height: 250px !important;
      }
    }
  }
`;
