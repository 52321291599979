import styled from 'styled-components';
import {
  SwitchingButtonsContainer,
  SwitchButton
} from '../../../../documents-page/components/puzzle-canvas-section/styled.components';

export const EventPartnerContainer = styled.div`
  padding: 60px 0;
  position: relative;
  .circle-right {
    position: absolute !important;
    right: -120px;
    img {
      width: 345px;
    }
  }

  @media only screen and (max-width: 1330px) {
    .circle-right {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1000px) {
    ${SwitchingButtonsContainer} {
      flex-direction: column;
    }
    ${SwitchButton} {
      width: 100%;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 500px) {
    ${SwitchingButtonsContainer} {
      width: 100%;
    }
  }
`;

export const EpTextContainer = styled.div`
  display: flex;
  max-width: 880px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  a {
    color: #5fa5c5;
  }
  @media only screen and (max-width: 1000px) {
    p {
      word-break: break-word !important;
    }
  }
`;

export const ProvideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const ReciveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const TextPRContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: left;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--UI-Blue-Tint, #f5fafd);

  a {
    color: #5fa5c5;
  }

  ul {
    margin: 0;
  }
`;
