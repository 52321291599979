import styled from 'styled-components';
import heroImage from '../../../../../images/partnerzy/benefits/benefits.webp';

export const BenefitsContainer = styled.div`
  padding: 60px 0;
  overflow: hidden;
  background-size: cover !important;
  background-position: center;
  width: 100%;
  background-image: url(${heroImage});
  display: block;
  background-attachment: scroll;

  .container {
    display: flex;
    /* height: 500px; */
    box-sizing: border-box;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    flex-shrink: 0;

    h1,
    h2 {
      text-align: center;
      max-width: 654px;
    }
  }
`;

export const BenefitsBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  max-width: 680px;
`;

export const BenefitsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const BenefitsImage = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--UI-Grey-blue, #c4dfec);

  color: var(--Pure-White, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  flex-shrink: 0; // ensure that this element always has it's width in flex container
`;

export const TilesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Tile = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 4px;
  padding: 8px;
  align-self: stretch;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--UI-Blue-Tint, #f5fafd);

  p {
    text-align: center;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;
