import styled from 'styled-components';

export const AdditionallyContainer = styled.div`
  margin-top: -25px;
  padding-bottom: 60px;
`;

export const AdditionallyTile = styled.div`
  display: flex;
  width: 380px;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  /* flex: 1 0 0; */
  align-self: stretch;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);

  p {
    line-height: 150%;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const AdditionallyImage = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--UI-Grey-blue, #c4dfec);

  color: var(--Pure-White, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
