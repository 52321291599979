import styled from 'styled-components';

export const PartnersContainer = styled.div`
  padding: 60px 0px;
  position: relative;

  h2 {
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .circle-left {
    position: absolute !important;
    top: 20px;
    left: 0px;
  }

  .circle-right {
    position: absolute !important;
    bottom: 20px;
    right: 0px;
  }

  @media only screen and (max-width: 1200px) {
    .circle-left,
    .circle-right {
      display: none;
    }
  }
`;

export const PartnerTile = styled.div`
  display: flex;
  width: 180px;
  height: 120px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;
`;
