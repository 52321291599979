import './mentoring.css';
import '../new-mentoring/styles/why-participate.css';
import '../../styles/variables.css';
import './styles/main.scss';

import * as React from 'react';

import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import HeroSection from './components/2025/hero-section';
import BecomePartnerSection from './components/2025/become-partner';
import CooperationTypeSection from './components/2025/cooperation-type';
import BenefitseSection from './components/2025/benefits';
import AdditionallySection from './components/2025/additionally';
import ProjectsSection from './components/2025/projects';
import PartnersSection from './components/2025/partners';
import CaseStudiesSection from './components/2025/case-studies';
import AdditionalMaterialsSection from './components/2025/additional-materials';
import EventPartnerSection from './components/2025/event-partner';
import GuidelinesSection from './components/2025/guidelines';
import EventsSection from './components/2025/events';
import HelloSection from './components/2025/hello';
import ContactSection from './components/2025/contact';

function PartnersComponent({ location }) {
  const { t } = useTranslation();
  return (
    <Layout withHeader={false}>
      <Seo
        title={t('partners.seo.title')}
        description={t('partners.seo.desc')}
        ogImage={ogImage}
      />
      <HeroSection />
      <BecomePartnerSection />
      <CooperationTypeSection />
      <BenefitseSection />
      <AdditionallySection />
      <ProjectsSection />
      <PartnersSection />
      <div id="case-study">
        <CaseStudiesSection />
      </div>
      <AdditionalMaterialsSection />
      <EventPartnerSection />
      <GuidelinesSection />
      <EventsSection />
      <HelloSection />
      <div id="contact">
        <ContactSection />
      </div>
    </Layout>
  );
}

export default PartnersComponent;
