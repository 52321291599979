import styled from 'styled-components';

export const GuidelinesContainer = styled.div`
  background: var(
    --Linear-Blues,
    linear-gradient(263deg, #6ebde9 0%, #0f5575 100%)
  );
  padding: 60px 0;
  position: relative;

  h2 {
    text-align: center;
  }

  .image {
    img {
      width: 270px;
    }
  }

  .circle-left {
    position: absolute !important;
    bottom: 47px;
    left: 32px;
  }

  .circle-right {
    position: absolute !important;
    top: 52px;
    right: 27px;
  }

  .download-svg {
    /* display: flex; */
    width: 37px;
    height: 37px;
    box-sizing: border-box;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--Light-Bg, #f5fafd);
    flex-shrink: 0;
    cursor: pointer;
  }

  .tooltip {
    margin-left: auto;
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background: #e5f2fa;
    color: #080e14;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 8px 4px;
    border-radius: 8px;

    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  @media only screen and (max-width: 1400px) {
    .circle-left,
    .circle-right {
      display: none;
    }
  }
`;

export const GuideComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;

  box-shadow: 0px 0px 10px 0px rgba(5, 28, 38, 0.2);
`;

export const UpGuide = styled.div`
  display: flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: var(--UI-Blue-Tint, #f5fafd);

  cursor: pointer;

  svg {
    padding: 8px;
    transition: transform 0.3s ease-in-out;
  }
  .rotate-svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
`;

export const BottomGuide = styled.div`
  display: flex;
  padding: 16px 200px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;

  .guidelines-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  a {
    color: #5fa5c5;
  }

  ul {
    margin: 0;
  }

  @media only screen and (max-width: 700px) {
    padding-right: 16px !important;
  }
`;

export const GuideParagraphContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-items: center;
`;

export const GuideParagraph = styled.p`
  color: var(--UI-Black, #080e14);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
`;

export const GuideSvgContainer = styled.div`
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const GuideSvgComponent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const GuideSvgTile = styled.div`
  display: flex;
  padding: 8px;
  /* align-items: flex-start; */
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
`;

export const SvgTile = styled.div`
  display: flex;
  padding: 4px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #e5f2fa;
  /* flex-shrink: 0; */

  color: var(--UI-Blue, #5fa5c5);
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.7px;
`;
