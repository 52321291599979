import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  FlexColumnContainer,
  H2Style,
  H3Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle,
  ParagraphSmallStyle
} from '../../../styled.components';
import {
  BenefitsBottom,
  BenefitsContainer,
  BenefitsImage,
  BenefitsTitleContainer,
  IconsContainer,
  Tile,
  TilesContainer
} from '../benefits/styled.components';
import { Trans } from 'react-i18next';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
const BenefitseSection = () => {
  const { t } = useTranslation();

  const [ref, inView] = useInView({
    triggerOnce: true
  });

  return (
    <>
      <BenefitsContainer>
        <div className="container">
          <FlexColumnContainer gap="8px">
            <ParagraphLargeStyle color="#F5FAFD">{t`partners.benefits-sub-title`}</ParagraphLargeStyle>
            <H2Style>{t`partners.benefits-title`}</H2Style>
          </FlexColumnContainer>
          <BenefitsBottom>
            <BenefitsTitleContainer>
              <BenefitsImage>📣</BenefitsImage>
              <H3Style>{t`partners.benefits-title2`}</H3Style>
            </BenefitsTitleContainer>
            <ParagraphMediumStyle color="#F5FAFD">{t`partners.benefits-description`}</ParagraphMediumStyle>
            <TilesContainer ref={ref}>
              <Tile>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M8 11V16M8 8V8.01M12 16V11M16 16V13C16 12.4696 15.7893 11.9609 15.4142 11.5858C15.0391 11.2107 14.5304 11 14 11C13.4696 11 12.9609 11.2107 12.5858 11.5858C12.2107 11.9609 12 12.4696 12 13M4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6Z"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <H3Style color="#0F5575">
                  {inView ? (
                    <CountUp
                      start={0}
                      end={350}
                      duration={3}
                      suffix={t`partners.benefits-thousand`}
                    />
                  ) : (
                    '0' + t`partners.benefits-thousand`
                  )}
                </H3Style>
                <ParagraphSmallStyle>
                  <Trans
                    i18nKey="partners.benefits-linkedin"
                    components={{
                      b: <b></b>
                    }}
                  />
                </ParagraphSmallStyle>
              </Tile>
              <Tile>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M3 7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H19C19.5304 5 20.0391 5.21071 20.4142 5.58579C20.7893 5.96086 21 6.46957 21 7M3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V7M3 7L12 13L21 7"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <H3Style color="#0F5575">
                  {inView ? (
                    <CountUp
                      start={0}
                      end={10}
                      duration={3}
                      prefix="+"
                      suffix={t`partners.benefits-thousand`}
                    />
                  ) : (
                    '+0' + t`partners.benefits-thousand`
                  )}
                </H3Style>
                <ParagraphSmallStyle>
                  <Trans
                    i18nKey="partners.benefits-mail"
                    components={{
                      b: <b></b>
                    }}
                  />
                </ParagraphSmallStyle>
              </Tile>
              <Tile>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none">
                  <path
                    d="M15 3H3C1.89543 3 1 3.89543 1 5V17C1 18.1046 1.89543 19 3 19H15C16.1046 19 17 18.1046 17 17V5C17 3.89543 16.1046 3 15 3Z"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 1V5"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 1V5"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 9H17"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 13H5V15H7V13Z"
                    stroke="#4EBFEE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <H3Style color="#0F5575">
                  {inView ? (
                    <>
                      <CountUp start={0} end={50} duration={3} /> -{' '}
                      <CountUp start={0} end={400} duration={3} />
                    </>
                  ) : (
                    '0 - 0'
                  )}
                </H3Style>
                <ParagraphSmallStyle>
                  <Trans
                    i18nKey="partners.benefits-calendar"
                    components={{
                      b: <b></b>
                    }}
                  />
                </ParagraphSmallStyle>
              </Tile>
              <Tile>
                <IconsContainer>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M7 10V14H10V21H14V14H17L18 10H14V8C14 7.73478 14.1054 7.48043 14.2929 7.29289C14.4804 7.10536 14.7348 7 15 7H18V3H15C13.6739 3 12.4021 3.52678 11.4645 4.46447C10.5268 5.40215 10 6.67392 10 8V10H7Z"
                      stroke="#4EBFEE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M16.5 7.5V7.51M4 8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4H16C17.0609 4 18.0783 4.42143 18.8284 5.17157C19.5786 5.92172 20 6.93913 20 8V16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20H8C6.93913 20 5.92172 19.5786 5.17157 18.8284C4.42143 18.0783 4 17.0609 4 16V8ZM9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z"
                      stroke="#4EBFEE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconsContainer>
                <H3Style color="#0F5575">
                  {inView ? (
                    <CountUp
                      start={0}
                      end={600}
                      duration={3}
                      prefix="+"
                      suffix={t`partners.benefits-thousand`}
                    />
                  ) : (
                    '+0' + t`partners.benefits-thousand`
                  )}
                </H3Style>
                <ParagraphSmallStyle>
                  <Trans
                    i18nKey="partners.benefits-meta"
                    components={{
                      b: <b></b>
                    }}
                  />
                </ParagraphSmallStyle>
              </Tile>
            </TilesContainer>
            <H3Style>{t`partners.benefits-additionally`}</H3Style>
          </BenefitsBottom>
        </div>
      </BenefitsContainer>
    </>
  );
};

export default BenefitseSection;
