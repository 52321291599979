import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  BottomGuide,
  GuideComponent,
  GuidelinesContainer,
  GuideParagraph,
  GuideParagraphContainer,
  GuideSvgComponent,
  GuideSvgContainer,
  GuideSvgTile,
  SvgTile,
  UpGuide
} from './styled.components';
import {
  FlexColumnContainer,
  H2Style,
  H4Style,
  ParagraphLargeStyle,
  ParagraphMediumStyle,
  ParagraphSmallStyle
} from '../../../styled.components';
import { Trans } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { navigate } from 'gatsby-link';

const GuidelinesSection = () => {
  const { t } = useTranslation();

  const documents = [
    {
      node: {
        title: 'Logo IHF kolor ciemny SVG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/6YBimmpR9ZqfFlaf08rXBL/4405bd183c6d6fbd6caa0165cd41bcc9/IHF_logo_ciemne.svg'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF kolor ciemny PNG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/6B7C1ACbaQ7dwU9MfK59hn/bdb93bb155807eeee28dd0aebdfa6d2e/IHF_logo_ciemne_4x.png'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF kolor jasny SVG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/4qcxwxnq34L7Oq3wbYpNzS/562e87014a8a02e3dc053759f86165cf/IHF_logo_jasne.svg'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF kolor jasny PNG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/2aK5Pcns4BNW3CJzXlZsNe/c25aca2224f6e9437633a370c1ec4882/IHF_logo_jasne_4x.png'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF czarne SVG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/65rBNV7qgWuxF4rRtyIRRt/b68757a717b081b4c972f79eca3f4807/IHF_logo_czarne.svg'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF czarne PNG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/5q1pXXApNS1UzDTr6snlOn/5cffb8451f431cf5f34179926f104ff5/IHF_logo_czarne_4x.png'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF białe SVG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/1cp5TPli8Hik7oDw3BtPuO/7d65bbdaf62131784bd8fdd40cf1435b/IHF_logo_biale.svg'
          }
        }
      }
    },
    {
      node: {
        title: 'Logo IHF białe PNG',
        file: {
          file: {
            url: 'https://images.ctfassets.net/d4b060axqd4d/tRAMpsp62jVhZWwBAEp44/0bcbe2d646d1df978fc9d73b41c13f4c/IHF_logo_biale_4x.png'
          }
        }
      }
    }
  ];

  const downloadFile = async (downloadedFile) => {
    try {
      const response = await fetch(downloadedFile.node.file.file.url);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = downloadedFile.node.title.replace(' ', '_');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const downloadFilesAsZip = async (downloadedFiles) => {
    if (!Array.isArray(downloadedFiles) || downloadedFiles.length === 0) {
      console.error('No files to download');
      return;
    }

    const zip = new JSZip();
    try {
      for (const file of downloadedFiles) {
        const response = await fetch(file.node.file.file.url);
        const blob = await response.blob();
        const extension = file.node.title.includes('SVG') ? '.svg' : '.png';
        const fileName = file.node.title.replace(/\s+/g, '_') + extension;
        zip.file(fileName, blob);
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      saveAs(zipBlob, 'downloaded_files.zip');
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  };

  const renderDocuments = documents?.map((document, index) => {
    return (
      <GuideSvgTile key={index}>
        <SvgTile>{document.node.title.includes('SVG') ? 'SVG' : 'PNG'}</SvgTile>
        <ParagraphMediumStyle>{document.node.title}</ParagraphMediumStyle>
        <svg
          onClick={() => downloadFile(document)}
          className="download-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none">
          <g clip-path="url(#clip0_4434_2695)">
            <path
              d="M4.5 17.5V19.5C4.5 20.0304 4.71071 20.5391 5.08579 20.9142C5.46086 21.2893 5.96957 21.5 6.5 21.5H18.5C19.0304 21.5 19.5391 21.2893 19.9142 20.9142C20.2893 20.5391 20.5 20.0304 20.5 19.5V17.5"
              stroke="#0F5575"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 11.5L12.5 16.5L17.5 11.5"
              stroke="#0F5575"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 4.5V16.5"
              stroke="#0F5575"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4434_2695">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </GuideSvgTile>
    );
  });

  const items = [
    {
      id: 'guidelines-publication',
      title: t`partners.guidelines-guide-title-1`,
      description: (
        <Trans
          i18nKey="partners.guidelines-guide-description-1"
          components={{
            li: <li></li>,
            ul: <ul></ul>
          }}
        />
      )
    },
    {
      id: 'guidelines-graphics',
      title: t`partners.guidelines-guide-title-2`,
      description: (
        <Trans
          i18nKey="partners.guidelines-guide-description-2"
          components={{
            li: <li></li>,
            ul: <ul></ul>,
            div: <div className="guidelines-description"></div>,
            b: <b></b>,
            span: <span></span>
          }}
        />
      )
    },
    {
      id: 'guidelines-application',
      title: t`partners.guidelines-guide-title-3`,
      description: (
        <Trans
          i18nKey="partners.guidelines-guide-description-3"
          components={{
            div: <div className="guidelines-description"></div>,
            b: <b></b>,
            span: <span></span>,
            a: <a href="mailto:hello@innovationshub.pl"></a>
          }}
        />
      )
    },
    {
      id: 'guidelines-identyfication',
      title: t`partners.guidelines-guide-title-4`,
      description: (
        <FlexColumnContainer alignItems="flex-start">
          <FlexColumnContainer gap="4px" alignItems="flex-start">
            <GuideParagraph>{t`partners.guidelines-guide-title-4-header-1`}</GuideParagraph>
            <ParagraphMediumStyle>{t`partners.guidelines-guide-title-4-description-1`}</ParagraphMediumStyle>
            <StaticImage
              class="image"
              src="../../../../../images/partnerzy/guidelines/inhub.webp"
              alt="image"
              loading="lazy"
            />
          </FlexColumnContainer>
          <FlexColumnContainer gap="4px" alignItems="flex-start">
            <GuideParagraphContainer>
              <GuideParagraph>
                {t`partners.guidelines-guide-title-4-header-2`}{' '}
              </GuideParagraph>
              <div class="tooltip">
                <span class="tooltiptext">{t`partners.guidelines-download-all`}</span>
                <svg
                  onClick={() => downloadFilesAsZip(documents)}
                  className="download-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none">
                  <g clip-path="url(#clip0_4434_2695)">
                    <path
                      d="M4.5 17.5V19.5C4.5 20.0304 4.71071 20.5391 5.08579 20.9142C5.46086 21.2893 5.96957 21.5 6.5 21.5H18.5C19.0304 21.5 19.5391 21.2893 19.9142 20.9142C20.2893 20.5391 20.5 20.0304 20.5 19.5V17.5"
                      stroke="#0F5575"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.5 11.5L12.5 16.5L17.5 11.5"
                      stroke="#0F5575"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5 4.5V16.5"
                      stroke="#0F5575"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4434_2695">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </GuideParagraphContainer>
            <GuideSvgContainer>
              <ParagraphSmallStyle>{t`partners.guidelines-guide-title-4-svg-title-1`}</ParagraphSmallStyle>
              <GuideSvgComponent>
                {renderDocuments.slice(0, 2)}
              </GuideSvgComponent>
              <GuideSvgComponent>
                {renderDocuments.slice(2, 4)}
              </GuideSvgComponent>
            </GuideSvgContainer>
            <GuideSvgContainer>
              <ParagraphSmallStyle>{t`partners.guidelines-guide-title-4-svg-title-2`}</ParagraphSmallStyle>
              <GuideSvgComponent>
                {renderDocuments.slice(4, 6)}
              </GuideSvgComponent>
            </GuideSvgContainer>
            <GuideSvgContainer>
              <ParagraphSmallStyle>{t`partners.guidelines-guide-title-4-svg-title-3`}</ParagraphSmallStyle>
              <GuideSvgComponent>
                {renderDocuments.slice(6, 8)}
              </GuideSvgComponent>
            </GuideSvgContainer>
          </FlexColumnContainer>
        </FlexColumnContainer>
      )
    }
  ];

  const [itemsStates, setItemsStates] = useState(
    new Array(items.length).fill(false)
  );

  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      if (hash === '#guidelines-publication') {
        setItemsStates((prev) => {
          const newStates = [...prev];
          newStates[0] = true;
          return newStates;
        });
      } else if (hash === '#guidelines-graphics') {
        setItemsStates((prev) => {
          const newStates = [...prev];
          newStates[1] = true;
          return newStates;
        });
      }
    }

    setTimeout(() => {
      removeHash();
    }, 500);
  }, [hash]);

  const removeHash = () => {
    if (hash === '#guidelines-publication' || hash === '#guidelines-graphics')
      navigate(
        window.location.pathname + window.location.search + '#guidelines',
        { replace: true }
      );
  };

  const renderItems = items.map((item, index) => {
    const isExpand = itemsStates?.[index] || false;

    const changeView = () => {
      setItemsStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = !newStates[index];
        return newStates;
      });
    };

    return (
      <GuideComponent id={item.id} key={index}>
        <UpGuide onClick={changeView}>
          <svg
            className={isExpand ? 'rotate-svg' : ''}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M18 9L12 15L6 9"
              stroke="#5FA5C5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <H4Style color="#080E14">{item.title}</H4Style>
        </UpGuide>
        <BottomGuide
          style={{
            maxHeight: isExpand ? '1500px' : '0',
            padding: isExpand ? '16px 200px 16px 16px' : '0 200px 0 16px'
          }}>
          {item.description}
        </BottomGuide>
      </GuideComponent>
    );
  });

  return (
    <>
      <GuidelinesContainer>
        <svg
          className="circle-left"
          xmlns="http://www.w3.org/2000/svg"
          width="98"
          height="161"
          viewBox="0 0 98 161"
          fill="none">
          <g opacity="0.2">
            <path
              d="M1.50043 83.4562C1.50043 77.0334 6.69077 71.8343 13.0842 71.8343C19.4776 71.8343 24.668 77.0335 24.668 83.4562C24.668 89.8789 19.4776 95.0781 13.0842 95.0781C6.69077 95.0781 1.50043 89.8789 1.50043 83.4562Z"
              fill="#F5FAFD"
              stroke="#F5FAFD"
              strokeWidth="3"
            />
            <path
              d="M16.3272 132.919C16.3272 119.33 27.2937 108.338 40.7906 108.338C54.2875 108.338 65.2539 119.33 65.2539 132.919C65.2539 146.509 54.2875 157.5 40.7906 157.5C27.2936 157.5 16.3272 146.509 16.3272 132.919Z"
              stroke="#F5FAFD"
              strokeWidth="7"
            />
            <ellipse
              cx="62.0838"
              cy="36.74"
              rx="35.9162"
              ry="36.7414"
              transform="rotate(-180 62.0838 36.74)"
              fill="#F5FAFD"
            />
          </g>
        </svg>
        <svg
          className="circle-right"
          xmlns="http://www.w3.org/2000/svg"
          width="207"
          height="126"
          viewBox="0 0 207 126"
          fill="none">
          <g opacity="0.4">
            <path
              d="M99.6992 1.49958C108.193 1.49958 115.07 8.36358 115.07 16.8216C115.07 25.2795 108.193 32.1436 99.6992 32.1436C91.2059 32.1436 84.3281 25.2796 84.3281 16.8216C84.3281 8.36358 91.2059 1.49958 99.6992 1.49958Z"
              fill="#F5FAFD"
              stroke="#F5FAFD"
              strokeWidth="3"
            />
            <path
              d="M36.1041 19.9917C54.1247 19.9917 68.7081 34.5352 68.7081 52.4446C68.7081 70.354 54.1247 84.8975 36.1041 84.8975C18.0835 84.8975 3.5 70.354 3.5 52.4446C3.5 34.5352 18.0835 19.9917 36.1041 19.9917Z"
              stroke="#F5FAFD"
              strokeWidth="7"
            />
            <ellipse
              cx="159.762"
              cy="79.822"
              rx="46.178"
              ry="47.239"
              transform="rotate(-90 159.762 79.822)"
              fill="#F5FAFD"
            />
          </g>
        </svg>
        <div className="container">
          <FlexColumnContainer gap="40px">
            <FlexColumnContainer gap="4px">
              <ParagraphLargeStyle color="#F5FAFD">{t`partners.guidelines-sub-title`}</ParagraphLargeStyle>
              <H2Style color="#F5FAFD">{t`partners.guidelines-title`}</H2Style>
            </FlexColumnContainer>
            <FlexColumnContainer alignItems="flex-start">
              {renderItems}
            </FlexColumnContainer>
          </FlexColumnContainer>
        </div>
      </GuidelinesContainer>
    </>
  );
};

export default GuidelinesSection;
