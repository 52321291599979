import * as React from 'react';
import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactContainer } from './styled.components';
import { H2Style } from '../../../styled.components';
import { Trans } from 'react-i18next';
import SelectForm from '../select-form';

const FormResult = (props) => {
  if (!props?.title && !props?.desc) return null;
  return (
    <div className="contact-form__form__result">
      <div
        className={`contact-form__form__result__icon ${
          props.success ? 'success' : 'error'
        }`}></div>
      <div className="contact-form__form__result__title">{props.title}</div>
      <p>{props.desc}</p>
    </div>
  );
};

const ContactSection = () => {
  const { t } = useTranslation();

  const ContactSchema = Yup.object().shape({
    name: Yup.string().required(t('partners.contact.required')),
    company: Yup.string().required(t('partners.contact.required')),
    email: Yup.string()
      .email(t('partners.contact.invalid-email'))
      .required(t('partners.contact.required')),
    cooperation: Yup.string().required(t`register-form.required`),
    partnership: Yup.string().test(
      'projectPartnership',
      t`register-form.required`,
      function (value) {
        const { cooperation } = this.parent;
        if (cooperation === 'Partnerstwo Projektowe') {
          return value !== undefined && value.length > 0;
        }
        return true;
      }
    ),
    phone: Yup.string()
      .required(t`register-form.required`)
      .matches(/^\+[0-9]{1,4}[0-9]{9}$/, t`partners.contact.invalid-phone`)
      .min(10, t`partners.contact.invalid-phone`),
    comment: Yup.string().optional()
  });

  const [status, setStatus] = useState(null);

  const cooperationOptions = [
    {
      label: t`partners.contact-ps`,
      value: 'Partnerstwo Strategiczne'
    },
    {
      label: t`partners.contact-pe`,
      value: 'Partnerstwo Ekosystemowe'
    },
    {
      label: t`partners.contact-pm/`,
      value: 'Partnerstwo Medialne / Patronat Medialny'
    },
    {
      label: t`partners.contact-pm`,
      value: 'Partnerstwo Merytoryczne'
    },
    {
      label: t`partners.contact-ph`,
      value: 'Patronat / Patronat Honorowy'
    },
    {
      label: t`partners.contact-pp`,
      value: 'Partnerstwo Projektowe'
    }
  ];

  const partnershipOptions = [
    {
      label: t`partners.contact-pi`,
      value: 'Programy Inkubacyjne'
    },
    {
      label: t`partners.contact-r`,
      value: 'Raport'
    },
    {
      label: t`partners.contact-fm`,
      value: 'Film krótkometrażowy'
    },
    {
      label: t`partners.contact-ap`,
      value: 'Akademia Przyszłości'
    },
    {
      label: t`partners.contact-ia`,
      value: 'Innovations Academy'
    },
    {
      label: t`partners.contact-i`,
      value: 'Inne'
    }
  ];

  return (
    <>
      <ContactContainer>
        <div className="circles">
          <StaticImage
            className="ii-left-top-circle"
            src="../../../../../images/partnerzy/contact/left-top-circle.webp"
            alt="Circle motive left"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="ii-left-bottom-circle"
            src="../../../../../images/partnerzy/contact/left-bottom-circle.webp"
            alt="Circle motive right"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="ii-right-top-circle"
            src="../../../../../images/partnerzy/contact/right-top-circle.webp"
            alt="Circle motive right"
            placeholder="none"
            loading="lazy"
          />
          <div className="contact-section-ctn">
            <div className="contact-header">
              <H2Style color="#0F5575">{t`partners.contact-title`}</H2Style>
            </div>
            <div className="contact-form__form">
              <FormResult {...status} />
              <Formik
                initialValues={{
                  name: '',
                  company: '',
                  email: '',
                  cooperation: '',
                  partnership: '',
                  phone: '',
                  comment: ''
                }}
                validationSchema={ContactSchema}
                onSubmit={async (values, { setErrors }) => {
                  console.log(values);
                  try {
                    const response = await axios.post(
                      'https://api.brevo.com/v3/smtp/email',
                      {
                        sender: { email: 'no-reply@innovationshub.pl' },
                        to: [{ email: 'hello@innovationshub.pl' }],
                        cc: [{ email: values.email }],
                        subject: 'Próba kontaktu z formularza',
                        htmlContent:
                          values.cooperation === 'Partnerstwo Projektowe'
                            ? `<p>Imię: ${values.name}</p>
                        <p>Firma: ${values.company}</p>
                        <p>Email: ${values.email}</p>
                        <p>Numer telefonu: ${values.phone}</p>
                        <p>Zakres współpracy: ${values.cooperation}</p>
                        <p>Wybrane projekty: ${values.partnership}</p>
                        <p>Komentarz: ${values.comment}</p>`
                            : `<p>Imię: ${values.name}</p>
                        <p>Firma: ${values.company}</p>
                        <p>Email: ${values.email}</p>
                        <p>Numer telefonu: ${values.phone}</p>
                        <p>Zakres współpracy: ${values.cooperation}</p>
                        <p>Komentarz: ${values.comment}</p>`
                      },
                      {
                        headers: {
                          'api-key': `${process.env.GATSBY_BREVO_API_KEY}`,
                          'Content-Type': 'application/json'
                        }
                      }
                    );
                    setStatus({
                      success: true,
                      desc: t('contact.form.result.success.desc'),
                      title: t('contact.form.result.success.title')
                    });
                  } catch (error) {
                    setStatus({
                      success: false,
                      desc: t('contact.form.result.error.desc'),
                      title: t('contact.form.result.error.title')
                    });
                  }
                  setTimeout(() => {
                    setStatus(null);
                  }, 10 * 1000);
                }}>
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <label className="form-item-wrapper">
                      <span>
                        {t('partners.contact.label-name')}{' '}
                        <span className="red-span">*</span>
                      </span>
                      <Field
                        placeholder={t('partners.contact.placeholder-name')}
                        className={errors?.name && touched?.name ? 'error' : ''}
                        id="name"
                        name="name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    <label className="form-item-wrapper">
                      <span>
                        {t('partners.contact.label-company')}{' '}
                        <span className="red-span">*</span>
                      </span>
                      <Field
                        placeholder={t('partners.contact.placeholder-company')}
                        className={
                          errors?.company && touched?.company ? 'error' : ''
                        }
                        id="company"
                        name="company"
                      />
                      <ErrorMessage name="company">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    <label className="form-item-wrapper">
                      <span>
                        {t('partners.contact.label-email')}{' '}
                        <span className="red-span">*</span>
                      </span>
                      <Field
                        id="email"
                        className={
                          errors?.email && touched?.email ? 'error' : ''
                        }
                        name="email"
                        type="email"
                        placeholder={t('partners.contact.placeholder-email')}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    <label className="form-item-wrapper">
                      <span>
                        {t('partners.contact.label-cooperation')}{' '}
                        <span className="red-span">*</span>
                      </span>
                      <SelectForm
                        name="cooperation"
                        type="cooperation"
                        options={cooperationOptions}
                        value={values.cooperation}
                        placeholder={t`partners.contact.placeholder-cooperation`}
                        noOptionsMessage={() => t`partners.contact.no-options`}
                        closeMenuOnSelect={true}
                        onChange={(selectedOption) => {
                          setFieldValue('cooperation', selectedOption.value);
                        }}
                      />
                      <ErrorMessage name="cooperation">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    {values.cooperation === 'Partnerstwo Projektowe' && (
                      <label className="form-item-wrapper">
                        <span>
                          {t('partners.contact.label-partnership')}{' '}
                          <span className="red-span">*</span>
                        </span>
                        <SelectForm
                          name="partnership"
                          type="partnership"
                          options={partnershipOptions}
                          value={values.partnership}
                          placeholder={t`partners.contact.placeholder-partnership`}
                          isMulti={true}
                          noOptionsMessage={() =>
                            t`partners.contact.no-options`
                          }
                          onChange={(selectedOptions) => {
                            let selectedString = '';
                            if (selectedOptions) {
                              if (Array.isArray(selectedOptions)) {
                                selectedString = selectedOptions
                                  .map((option) => option.value)
                                  .join(', ');
                              } else {
                                selectedString = selectedOptions.value;
                              }
                            }
                            setFieldValue('partnership', selectedString);
                          }}
                        />
                        <ErrorMessage name="partnership">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </label>
                    )}
                    <label className="form-item-wrapper">
                      <span>
                        {t('partners.contact.label-phone')}{' '}
                        <span className="red-span">*</span>
                      </span>
                      <Field
                        placeholder={t('partners.contact.placeholder-phone')}
                        className={
                          errors?.phone && touched?.phone ? 'error' : ''
                        }
                        id="phone"
                        name="phone"
                      />
                      <ErrorMessage name="phone">
                        {(msg) => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </label>
                    <label className="form-item-wrapper">
                      <span>{t('partners.contact.label-comment')}</span>
                      <Field
                        as="textarea"
                        rows="4"
                        placeholder={t('partners.contact.placeholder-comment')}
                        id="comment"
                        name="comment"
                      />
                    </label>
                    <p className="rodo">
                      <Trans
                        i18nKey="partners.contact-rodo"
                        components={{
                          a: <a href="/rodo"></a>
                        }}
                      />
                    </p>
                    <Field
                      name="submit"
                      className="contact-form__form__submit"
                      type="submit"
                      value={t('contact.form.submit')}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </ContactContainer>
    </>
  );
};

export default ContactSection;
