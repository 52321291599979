import styled from 'styled-components';

export const HelloContainer = styled.div``;

export const RelativeContainer = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  margin-top: auto;
  height: 490px;

  @media only screen and (max-width: 1200px) {
    height: auto;
    width: auto;
    margin: auto;
    .image {
      img {
        width: 500px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .image {
      img {
        width: 400px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .image {
      img {
        width: 280px;
      }
    }
  }
`;

export const HelloRightContainer = styled.div`
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media only screen and (max-width: 1200px) {
    padding-top: 0px;
  }
`;

export const HelloContactTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .svg-image {
    width: 32px;
    height: 32px;
    padding: 4px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--White, rgba(255, 255, 255, 0.9));
    box-shadow: 0px 0px 10px 0px rgba(5, 28, 38, 0.2);
  }

  a {
    text-decoration: none;
  }
`;
