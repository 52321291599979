import styled from 'styled-components';

export const CustomSelect = styled.div`
  width: 100%;

  .select-input__control {
    padding: 0;
    border-radius: 5px;
    border: 1px solid var(--UI-Dark-Blue, #0f5575);
    background: #fff;
  }

  .select-input__value-container {
    padding: 0 10px 0 17px;
    min-height: 53px;
    color: var(--UI-Black, #080e14);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.36px;
  }

  .select-input__input-container {
    padding: 0;
  }

  .select-input__input {
    height: 21.6px !important;
  }

  .select-input__placeholder {
    color: var(--UX-Grey-2, #c4c4c4);
  }

  .select-input__indicators {
    padding-right: 17px;
  }

  .select-input__option--is-focused {
    background-color: #5fa5c5;
    color: white;
  }

  .select-input__option--is-selected {
    background-color: #5fa5c5;
    color: white;
  }

  .select-input__multi-value {
    border-radius: 5px;
    background: #5fa5c5;

    color: #f5fafd !important;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    padding: 4px;
    padding-left: 8px;

    div {
      color: #f5fafd !important;
    }
  }

  .select-input__multi-value__remove {
    &:hover {
      cursor: pointer;
      background-color: #5fa5c5 !important;
    }
  }
`;
