import React from 'react';
import Select from 'react-select';
import { CustomSelect } from './styled.components';

const SelectForm = ({
  options,
  value,
  placeholder,
  onChange,
  defaultValue,
  isMulti = false,
  noOptionsMessage,
  closeMenuOnSelect = false
}) => {
  let normalizedValue;
  if (isMulti) {
    if (typeof value === 'string' && value.length > 0) {
      const valuesArray = value.split(',').map((item) => item.trim());
      normalizedValue = options.filter((option) =>
        valuesArray.includes(option.value)
      );
    } else {
      normalizedValue = [];
    }
  } else {
    normalizedValue = options.find((option) => option.value === value) || null;
  }

  const CustomIndicator = (props) => {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <g clipPath="url(#clip0_3194_6036)">
          <path
            d="M6 9L12 15L18 9"
            stroke="#0F5575"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3194_6036">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  return (
    <CustomSelect>
      <Select
        components={{
          DropdownIndicator: CustomIndicator,
          IndicatorSeparator: () => null
        }}
        isMulti={isMulti}
        options={options}
        value={normalizedValue}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        closeMenuOnSelect={closeMenuOnSelect}
        className="select-input-container"
        classNamePrefix="select-input"
        noOptionsMessage={noOptionsMessage}
      />
    </CustomSelect>
  );
};

export default SelectForm;
