import styled from 'styled-components';

export const EventsContainer = styled.div`
  padding: 60px 0px;
  background: var(--UI-Blue-Tint, #f5fafd);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  h2 {
    text-align: center;
  }
`;

export const EventTile = styled.div`
  display: flex;
  width: 280px;
  box-sizing: border-box;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
`;
