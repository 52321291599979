import styled from 'styled-components';

export const CooperationTypeContainer = styled.div`
  padding: 60px 0;
  background: var(--UI-Blue-Tint, #f5fafd);

  h2 {
    max-width: 654px;
    text-align: center;
  }
`;

export const CooperationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  overflow: hidden; /* Ensures children don't exceed border-radius */
  border: 1px solid var(--UI-Grey-blue, #c4dfec);
  box-shadow: 0px 0px 20px 0px rgba(15, 83, 115, 0.2);
`;

export const CooperationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--UI-Grey-blue, #c4dfec);
  background: var(--UI-Blue-Tint, #f5fafd);
  cursor: pointer;

  .minus-icon {
    border-radius: 50%;
    background-color: #6ebde9;
    padding: 10px;
  }

  .plus-icon {
    border-radius: 50%;
    background-color: #ffffff;
    padding: 10px;
  }

  &:hover {
    .minus-icon {
      background-color: #5fa5c5;
    }
    .plus-icon {
      background-color: #f5fafd;
    }
  }
`;

export const CooperationTopContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #fff;
`;

export const CooperationTopTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const CooperationTopImagesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const CooperationBottomContainer = styled.div`
  padding: 16px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out;
`;
