import styled from 'styled-components';
import successImage from '../../../../../images/contact/success.svg';
import errorImage from '../../../../../images/contact/error.svg';

export const ContactContainer = styled.div`
  position: relative;

  .contact-section-ctn {
    padding: 60px 0;
    background: var(--UI-Blue-Tint, #f5fafd);
    border-bottom: 1px solid var(--Light, #5fa5c5);
  }

  .ii-left-top-circle {
    position: absolute !important;
    top: 17%;
    left: 6%;
  }

  .ii-left-bottom-circle {
    position: absolute !important;
    bottom: 7%;
    left: -3%;
  }

  .ii-right-top-circle {
    position: absolute !important;
    top: 19%;
    right: 0%;
  }

  .contact-section-ctn .contact-header {
    margin: auto;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .contact-section-ctn .contact-header h2 {
    margin: 0;
    color: var(--UI-Dark-blue, #0f5575);
    line-height: 140%;
    letter-spacing: -0.72px;
  }

  .contact-section-ctn .contact-header h3 {
    margin: 0;
    color: var(--UI-Dark-blue, #0f5575);
  }

  .contact-section-ctn .contact-form__form {
    margin: auto;
    border-radius: 0.625rem;
    max-width: 36.25rem;
    min-width: 36.25rem;
    position: relative;
    padding: 1.5rem;
    background: var(--Light-Bg, #fff);
    box-shadow: 2px 2px 20px 0px rgba(18, 102, 140, 0.15);
  }

  .contact-section-ctn .contact-form__form__result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    height: 100%;
    border-radius: 0.625rem;
    width: 100%;
    z-index: 8;
  }

  .contact-section-ctn .contact-form__form__result__icon {
    width: 9.375rem;
    height: 9.375rem;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: contain;
    background-position-x: left;
  }

  .contact-section-ctn .contact-form__form__result__icon.success {
    background-image: url(${successImage});
  }

  .contact-section-ctn .contact-form__form__result__icon.error {
    background-image: url(${errorImage});
  }

  .contact-section-ctn .contact-form__form__result p {
    max-width: 26.25rem;
    font-size: 1.125rem;
    letter-spacing: -0.02em;
    line-height: 1.6875rem;
    font-weight: 400;
  }

  .contact-section-ctn .contact-form__form__result__title {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8125rem;
    letter-spacing: 0;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .contact-section-ctn .contact-form__form__submit {
    cursor: pointer;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    font-size: 0.875rem;
    font-family: 'Montserrat';
    font-weight: 600;
    background-color: #0f5575;
    border-radius: 5px;
    border: 2px solid #0f5575;
    color: var(--UI-Blue-Tint, #f5fafd);
    width: 100%;
    max-width: 9.625rem;
    margin-top: 1rem;
    transition: all 0.2s ease-out;
  }

  .contact-section-ctn .contact-form__form__submit:hover {
    opacity: 1;
    color: var(--inhub-dark-blue);
    background-color: rgba(0, 0, 0, 0);
  }

  .contact-section-ctn .contact-form__form .rodo {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    text-align: justify;
  }

  .contact-section-ctn .contact-form__form .form-item-wrapper {
    padding: 0;
    padding-bottom: 1.5rem;
  }

  .contact-section-ctn .contact-form__form .form-item-wrapper input,
  .contact-section-ctn .contact-form__form .form-item-wrapper textarea {
    font-family: 'Raleway';
    border-width: 0.0625rem;
    resize: none;
    border-radius: 5px;
    border: 1px solid var(--UI-Dark-Blue, #0f5575);
    background: #fff;
  }

  .contact-section-ctn
    .contact-form__form
    .form-item-wrapper
    input::placeholder,
  .contact-section-ctn
    .contact-form__form
    .form-item-wrapper
    textarea::placeholder {
    color: var(--UX-Grey-2, #c4c4c4);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;
  }

  .contact-section-ctn .contact-form__form .form-item-wrapper span {
    padding-bottom: 0.5rem;
    color: var(--UI-Black, #080e14);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  .contact-section-ctn .contact-form__form .form-item-wrapper .red-span {
    color: var(--error, #ff0c0c);
  }

  @media only screen and (max-width: 80rem) {
    .ii-left-top-circle,
    .ii-left-bottom-circle,
    .ii-right-top-circle {
      height: 0;
    }

    .contact-section-ctn .contact-form {
      background-position-y: 32rem, 80rem;
      background-position-x: calc(100vw - (100vw - 35rem) / 2),
        calc((100vw - 70rem) / 2);
    }

    .contact-section-ctn .contact-form__body {
      flex-direction: column;
      align-items: center;
    }

    .contact-section-ctn .contact-form__form {
      min-width: unset;
    }

    .contact-section-ctn .contact-form__items {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 700px) {
    .contact-section-ctn .contact-form {
      margin: 0 1rem;
      background-image: none;
    }

    .contact-section-ctn .contact-form__header__title {
      font-size: 2rem;
    }

    .contact-section-ctn .contact-form__item {
      min-width: unset;
    }

    .contact-section-ctn .contact-form__item-title {
      font-size: 1.5rem;
    }

    .contact-section-ctn .contact-header {
      margin: 0 24px 24px 24px;
    }
  }
`;
