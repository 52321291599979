import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  FlexColumnContainer,
  FlexRowContainer,
  H2Style
} from '../../../styled.components';
import {
  AdditionalMaterialsContainer,
  ADLeftContainer
} from './styled.components';
import {
  DownloadTile,
  PdfTile
} from '../../../../documents-page/components/puzzle-canvas-section/styled.components';
import { ParagraphMediumStyle } from '../../../../documents-page/styled.components';
import { useState } from 'react';
import DocumentsModal from '../../../../../components/modals/documents/DocumentsModal';
import { StaticImage } from 'gatsby-plugin-image';

const AdditionalMaterialsSection = () => {
  const { t } = useTranslation();

  const documents = [
    {
      node: {
        id: 1,
        title: t`partners.am-document-1`,
        file: {
          file: {
            url: 'https://www.innovationshub.pl/Dzia%C5%82ano%C5%9B%C4%87%20Fundacji%202021-2023.pdf'
          }
        }
      }
    },
    {
      node: {
        id: 2,
        title: t`partners.am-document-2`,
        file: {
          file: {
            url: 'https://www.innovationshub.pl/Ulotki_inwestorskie_Gala_Fina%C5%82owa_2024_display.pdf'
          }
        }
      }
    },
    {
      node: {
        id: 3,
        title: t`partners.am-document-3`,
        file: {
          file: {
            url: 'https://www.innovationshub.pl/ExploreStartupHubinEuropePOLAND_ENG.pdf'
          }
        }
      }
    }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);

  const downloadFile = async (downloadedFile) => {
    try {
      const response = await fetch(downloadedFile.node.file.file.url);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = downloadedFile.node.title.replace(' ', '_');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const openModal = (document) => {
    if (document.node.id === 1) {
      downloadFile(document);
    } else {
      setCurrentDocument(document);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <DocumentsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfDocument={currentDocument}
      />
      <AdditionalMaterialsContainer>
        <div className="container">
          <FlexRowContainer gap="40px">
            <ADLeftContainer>
              <H2Style color="#0F5575">{t`partners.am-title`}</H2Style>
              <FlexColumnContainer alignItems="flex-start">
                {documents.map((document) => (
                  <DownloadTile>
                    <PdfTile>PDF</PdfTile>
                    <ParagraphMediumStyle>
                      {document.node.title}
                    </ParagraphMediumStyle>
                    <svg
                      onClick={(event) => {
                        event.stopPropagation();
                        openModal(document);
                      }}
                      className="download-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none">
                      <g clip-path="url(#clip0_4434_2695)">
                        <path
                          d="M4.5 17.5V19.5C4.5 20.0304 4.71071 20.5391 5.08579 20.9142C5.46086 21.2893 5.96957 21.5 6.5 21.5H18.5C19.0304 21.5 19.5391 21.2893 19.9142 20.9142C20.2893 20.5391 20.5 20.0304 20.5 19.5V17.5"
                          stroke="#5FA5C5"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.5 11.5L12.5 16.5L17.5 11.5"
                          stroke="#5FA5C5"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.5 4.5V16.5"
                          stroke="#5FA5C5"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4434_2695">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0.5 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </DownloadTile>
                ))}
              </FlexColumnContainer>
            </ADLeftContainer>
            <StaticImage
              className="image"
              src="../../../../../images/partnerzy/am/am-image.webp"
              alt="image"
              placeholder="none"
              loading="lazy"
            />
          </FlexRowContainer>
        </div>
      </AdditionalMaterialsContainer>
    </>
  );
};

export default AdditionalMaterialsSection;
