import styled from 'styled-components';

export const H1Style = styled.h1`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin: 0;
`;

export const H2Style = styled.h2`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.72px;
  margin: 0;
`;

export const H2SubStyle = styled.h2`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -0.72px;
  margin: 0;
`;

export const H3Style = styled.h3`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
`;

export const H4Style = styled.h3`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.36px;
  margin: 0;
`;

export const ParagraphLargeStyle = styled.p`
  color: ${(props) => props.color || '#080E14'};
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.36px;
`;

export const ParagraphMediumStyle = styled.p`
  color: ${(props) => props.color || '#080E14'};
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
`;

export const ParagraphSmallStyle = styled.p`
  color: ${(props) => props.color || '#080E14'};
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const WhiteButton = styled.button`
  display: inline-flex;
  height: 55px;
  box-sizing: border-box;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--UI-Blue-Tint, #f5fafd);
  border: 2px solid var(--UI-Blue-Tint, #f5fafd);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;

  text-decoration: none;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--UI-Blue-Tint, #f5fafd);

    color: var(--UI-Blue-Tint, #f5fafd);
  }

  &.disable {
    pointer-events: none;
    background: #dddddd;
    border: 2px solid #dddddd;
    color: #ffffff !important;
  }
`;

export const BlueButton = styled.button`
  display: inline-flex;
  height: 55px;
  box-sizing: border-box;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid var(--UI-Dark-Blue, #0f5575);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;

  text-decoration: none;

  &:hover {
    opacity: 1;
    background: var(--UI-Light-Blue, #4ebfee);
    border: 2px solid var(--UI-Blue-Tint, #4ebfee);

    color: var(--UI-Blue-Tint, #fff);
  }

  &.disable {
    pointer-events: none;
    background: #dddddd;
    border: 2px solid #dddddd;
    color: #ffffff !important;
  }
`;

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || 'center'};
  gap: ${(props) => props.gap || '16px'};
  align-self: stretch;
`;

export const FlexRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.gap || '16px'};
  align-self: stretch;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FlexGridLeftContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${(props) => props.gap || '20px'};
  flex-wrap: wrap;
`;

export const FlexGridCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: ${(props) => props.gap || '20px'};
  flex-wrap: wrap;
`;

export const CarouselContainer = styled.div`
  width: ${(props) => props.width || '100%'};
  position: relative;
  margin: auto;

  .slick-slider,
  .slick-slider .slick-slide div {
    padding: 0;
  }

  .slick-arrow {
    display: flex;
    width: 24.8px;
    height: 24.8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 2px solid var(--UI-Dark-Blue, #f5fafd);
    top: -12%;
    transform: none;
    z-index: 2;
    transition: all 0.2s ease-out;

    path {
      stroke: #f5fafd !important;
    }
  }

  .slick-arrow:hover {
    background: var(--UI-Light-Blue, #4ebfee);
    border: 2px solid var(--UI-Dark-Blue, #4ebfee);
  }

  .slick-arrow:hover path {
    stroke: #f5fafd !important;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
  /* .slick-dots {
    bottom: 0;
    top: -50px;
  }

  .slick-dots li {
    margin: 0 4px;
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li button {
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background: var(--UI-Grey-blue, #c4dfec);
  }

  .slick-active {
    width: 32px;
  }

  .slick-dots .slick-active button {
    width: 32px;
    background: var(--UI-Light-Blue, #4ebfee);
  } */

  .slider-pagination {
    color: var(--Mid, #fff);
    text-align: center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 34px;
    margin-right: 0 !important;
  }
  .slick-dots {
    display: none !important;
  }

  @media only screen and (max-width: 1200px) {
    .slick-arrow {
      top: -65px;
    }
    /* .slick-dots {
      display: none !important;
    }
    .slider-pagination {
      margin-right: 0 !important;
    } */
  }
`;
